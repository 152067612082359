import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { grey } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: {
      default: grey[200]
    },
    primary: {
      light: grey[100],
      main: grey[500],
      dark: grey[900],
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    button: {
      light: grey[700],
      main: grey[800],
      dark: grey[900],
      contrastText: '#fff',
    }
  },
  typography: {
    h3: {
      color: 'red'
    },
    small: {
      fontSize: '0.7rem'
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 13,
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginTop: 5,
          "& .MuiTypography-root": {
            fontSize: '0.8rem',
            lineHeight: '1rem',
          },
        }
      }
    },
    // MuiStack: {
    //   styleOverrides: {
    //     root: {
    //       "& span": {
    //         fontSize: '0.7rem',
    //         lineHeight: '0.5rem',
    //       },
    //     }
    //   }
    // },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 13,
          fontSize: '1rem'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input": {
            paddingBottom: 15,
            paddingTop: 15,
            height: '1rem',
            lineHeight: '1rem',
          },
          "& label": {
            lineHeight: '1rem'
          },
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          textDecoration: 'none'
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          display:'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:active': {
            outline: 'none',
          },
          '&:target': {
            outline: 'none',
          },
          '&:focus': {
            outline: 'none',
          },
          // "& .MuiPaper-root": {
          //   background: '#fff',
          //   overflowY: 'scroll',
          //   maxWidth: 400,
          //   maxHeight: '90vh',
          // },
        }
      }
    }
  },
});
theme.typography.h3 = {
  fontSize: '2rem'
}
theme.components.MuiTextField.styleOverrides.root = { ...theme.components.MuiTextField.styleOverrides.root, 
  "& .MuiInputBase-root": { borderRadius: 13, background: theme.palette.primary.light, border: "0 !important" }
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <Router>
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='/:cid' element={<App />} />
      </Routes>
    </Router>
  </ThemeProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
